<div class="page-main-container">
  <!-- Service grid -->
  <div class="service section-gap section-home">
    <div class="container">
      <div class="section-title-wrapper mb-0">
        <div class="row">
          <div class="col-sm-12 col-md-10 mx-auto text-center">
            <!-- <h2>Best Solutions For Your Business Needs!</h2> -->
            <h4 class="text-white">We are a software development company focused on using the latest technology to provide the best solution for your bussiness.</h4>
            <!-- <p class="mb-0">
              Our mission is to make your dreams reality.<br /> We bring
              happiness to our customers and our employees.<br /> We are not a just a company. We are
              experience, impact and emotion.
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Featured section -->
  <div class="mob-feature" [ngStyle]="{'background-attachment': isIos ? 'scroll' : 'fixed'}">
    <div class="section-gap">
      <div class="container">
        <div class="section-title-wrapper">
          <div class="row">
            <div class="col-sm-12 col-md-10 mx-auto text-center text-white">
              <h2 class="text-white"> Quality solutions for your business </h2>
              <!-- <p class="mb-0"></p> -->
            </div>
          </div>
        </div>
        <div class="row" angly-mobileFeatured [mobileFeatured]="mobileFeatured"></div>
      </div>
    </div>
  </div>
</div>