<!-- Call to action  -->
<div class="work-with-us">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-7 mx-auto text-center">
				<h2 class="text-white font-3x fw-bold mb-3" *ngIf="heading"> {{heading}}</h2>
				<h3 class="text-white mb-5" *ngIf="subHeading"> {{ subHeading }}</h3>
		        <div>
		          <a href="javascript:void(0);" routerLinkActive="active-link" class="btn btn-outline-light btn-lg btn-chk">Get 
		          started Today!</a>
		        </div>
			</div>
		</div>
	</div>
</div>
