<!-- Our Goles and mission  -->
<div class="our-mission bg-white section-gap">
   <div class="container">
        <p>Doxita is a software development company which has a young innovative team, oriented on high technology and complex software solutions. Our mission is to make your dreams reality. We bring happiness to our customers and our employees. We are not a just a company. We are experience, impact and emotion.</p>
   </div>
</div>
<!-- Contact information section -->
<div class="contact-section py-6">
   <div class="container">
      <div class="row">
         <div class="col-sm-12 col-md-9 mx-auto text-center">
            <div class="mb-5">
               <h2 class="font-2x"> Contact Information </h2>
            </div>
         </div>
      </div>
      <div class="row" angly-homeContact [contact]="contact"></div>
   </div>
</div>