import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { ContactComponent } from './contact/contact/contact.component';
import { MenuBlockComponent } from './menuBlock/menuBlock.component';
import { CallToActionComponent } from './callToAction/callToAction.component';
import { SocialsComponent } from './socialShare/socials/socials.component';
import { CategoriesListComponent } from './list/categoriesList/categoriesList.component';
import { SubscribeComponent } from './subsribeForm/subscribe/subscribe.component';
import { SearchNowComponent } from './searchNow/searchNow.component';
import { HomeContactComponent } from './contact/homeContact/homeContact.component';
import { SendMessageComponent } from './contact/sendMessage/sendMessage.component';
import { MobileFeaturedComponent } from './mobileFeatured/mobileFeatured.component';
import { PopularPostsComponent } from './list/popularPosts/popularPosts.component';
import { MapComponent } from './contact/map/map.component';
import { SocialShareListComponent } from './list/socialShareList/socialShareList.component';
import { SocialTagsComponent } from './socialShare/socialTags/socialTags.component';
import { RecentCommentsWithDateComponent } from './list/recentCommentsWithDate/recentCommentsWithDate.component';
import { RecentCommentsWithIconComponent } from './list/recentCommentsWithIcon/recentCommentsWithIcon.component';
import { SubsribeSidebarComponent } from './subsribeForm/subsribeSidebar/subsribeSidebar.component';
import { ContactUsListComponent } from './list/contactUsList/contactUsList.component';
import { ContactUs2Component } from './contact/contactUs2/contactUs2.component';
import { CartComponent } from './list/cart/cart.component';
import { TabStructureComponent } from './tabStructure/tabStructure.component';
import { LatestTweetsComponent } from './list/latestTweets/latestTweets.component';
import { Category2Component } from './list/category2/category2.component';
import { Tags1Component } from './tags/tags1/tags1.component';
import { Tags2Component } from './tags/tags2/tags2.component';
import { ArchiveComponent } from './list/archive/archive.component';
import { AboutAuthorComponent } from './aboutAuthor/aboutAuthor.component';
import { FooterLogoListComponent } from './footerLogoList/footerLogoList.component';
import { TechnologiesListComponent } from './technologies-list/technologies-list.component';
import { AddToHomescreenComponent } from './add-to-homescreen/add-to-homescreen.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule
  ],
  declarations: [
    ContactComponent,
    MenuBlockComponent,
    CallToActionComponent,
    SocialsComponent,
    CategoriesListComponent,
    SubscribeComponent,
    SearchNowComponent,
    HomeContactComponent,
    SendMessageComponent,
    MobileFeaturedComponent,
    PopularPostsComponent,
    MapComponent,
    SocialShareListComponent,
    SocialTagsComponent,
    RecentCommentsWithDateComponent,
    RecentCommentsWithIconComponent,
    SubsribeSidebarComponent,
    ContactUsListComponent,
    ContactUs2Component,
    CartComponent,
    TabStructureComponent,
    LatestTweetsComponent,
    Category2Component,
    Tags1Component,
    Tags2Component,
    ArchiveComponent,
    AboutAuthorComponent,
    FooterLogoListComponent,
    TechnologiesListComponent,
    AddToHomescreenComponent
  ],
  exports: [
    ContactComponent,
    MenuBlockComponent,
    CallToActionComponent,
    SocialsComponent,
    CategoriesListComponent,
    SubscribeComponent,
    SearchNowComponent,
    HomeContactComponent,
    SendMessageComponent,
    MobileFeaturedComponent,
    PopularPostsComponent,
    MapComponent,
    SocialShareListComponent,
    SocialTagsComponent,
    RecentCommentsWithIconComponent,
    RecentCommentsWithDateComponent,
    SubsribeSidebarComponent,
    ContactUsListComponent,
    ContactUs2Component,
    CartComponent,
    TabStructureComponent,
    LatestTweetsComponent,
    Category2Component,
    Tags1Component,
    Tags2Component,
    ArchiveComponent,
    AboutAuthorComponent,
    FooterLogoListComponent,
    TechnologiesListComponent,
    AddToHomescreenComponent
  ]
})
export class WidgetsModule { }
