import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class PageTitleService {
  private titleSubject = new BehaviorSubject<string>(null);
  private subTitleSubject = new BehaviorSubject<string>(null);

  public title = this.titleSubject.asObservable().pipe(distinctUntilChanged());
  public subTitle = this.subTitleSubject.asObservable().pipe(distinctUntilChanged());

  setTitle(value: string) {
    this.titleSubject.next(value);
  }

  setSubTitle(value: string) {
    this.subTitleSubject.next(value);
  }
}
