import { Component, OnInit } from '@angular/core';
import { ChkService } from '../service/chk.service';
declare var $: any;

@Component({
   selector: 'angly-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
   /* Variables */
   homeContent: any;
   services: any;
   projectGallary: any;
   posts: any;
   team: any;
   pricingContent: any;
   contact: any;
   videoContent: any;
   mobileFeatured: any;
   testimonial: any;
   isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
   subscribeFormClasses: any = { rowClass: 'row', fieldClass: 'col-sm-12 col-md-6' }

   constructor(private service: ChkService) {

      this.service.getHomeContent().subscribe(
         response => this.homeContent = response,
         err => console.log(err),
         () => this.getContent(this.homeContent)
      );
   }

   ngOnInit() { }


   /*
    * getContent is used for get the home page content.
    * Used variables is videoContent and mobileFeatured.
    */
   getContent(content) {
      this.videoContent = content.video_content;
      this.mobileFeatured = content.mobile_featured;
   }

}
