import { Component, OnInit, HostListener, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'angly-add-to-homescreen',
  templateUrl: './add-to-homescreen.component.html',
  styleUrls: ['./add-to-homescreen.component.css']
})
export class AddToHomescreenComponent implements OnInit {
  @Output() popupClick = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  @HostListener('click')
  onClick() {
    window.localStorage.setItem('installMessageClicked', '1');
    this.popupClick.emit(true);
  }

}
