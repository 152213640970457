<!--  Top menu -->
<nav id="mainNav" class="navbar navbar-expand-md navbar-dark fixed-top">
	<div class="container">
		<a class="navbar-brand" routerLink="/">
			DOXITA
			<!-- <img src="assets/img/angly-logo.png" class="img-fluid" width="110" height="37"> -->
		</a>
		<button class="navbar-toggler" type="button"(click)="isCollapsed = !isCollapsed" aria-controls="navbarCollapse" 
			[attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarCollapse" [collapse]="isCollapsed" [isAnimated]="true">
			<ul id="main" class="navbar-nav ml-auto main-menu list-unstyled">
				<li class="nav-item" routerLinkActive="open" *ngFor="let menuItem of menuItems.getMainMenu()">
					<a class="nav-link" [routerLink]="menuItem.state" routerLinkActive="active-link" 
						*ngIf="menuItem.type == 'link'" (click)="isCollapsed = true">{{ menuItem?.name }}</a>
					<a *ngIf="menuItem.type == 'sub'" class="nav-link" href="javascript:void(0)">{{ menuItem?.name }}&nbsp;
						<i class="{{ menuItem?.icon }}"></i>
					</a>
					<ul *ngIf="menuItem.type == 'sub' && menuItem?.children" class="sub-menu arrow-up list-unstyled" >
						<li class="nav-item" routerLinkActive="open" *ngFor="let grandchildItem of menuItem?.children">
							<a class="nav-link" [routerLink]="grandchildItem.state" routerLinkActive="active-link"
								*ngIf="grandchildItem.type == 'link'" (click)="isCollapsed = true">{{ grandchildItem?.name }}</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</nav>