import { Component, OnInit, Input } from '@angular/core';
import { ChkService } from '../../service/chk.service';

@Component({
  selector: 'app-technologies-list',
  templateUrl: './technologies-list.component.html',
  styleUrls: ['./technologies-list.component.css']
})
export class TechnologiesListComponent implements OnInit {
  @Input() keys: string[] = [];
  @Input() technologies: any;
  @Input() color: string;

  constructor() { }

  ngOnInit() { }

}
