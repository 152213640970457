<div *ngIf="router?.url != '/sign-up' && router?.url != '/login' && router?.url != '/thank-you' && router?.url != '/not-found' && router?.url != '/maintenance'">
    <!-- Footer section 2 -->
    <div angly-footerLogoList [logoList]="footerLogo" class="client-logo"></div>
    <div class="footer-wrapper-2 py-5 bg-light">
        <div class="container container-full-width">
            <div class="row">
                <div class="col-sm-9 col-md-8">
                    <div class="address" angly-contact location="бул. Липник 123, Русе, България" mail="office@doxita.com">
                    </div>
                </div>
                <div class="col-sm- col-md-4">
                    <span class="text-muted">&copy; 2024, Doxita team </span>
                </div>
            </div>
        </div><!-- container closed -->
    </div><!-- Footer wrapper closed -->
    <!-- Back To Top -->
    <a href="#" id="back-top" class="to-top scrolled" (click)="topScroll($event)"> <span class="to-top-icon"></span>
    </a>
</div>