<div class="solutions-container">
  <!-- Video section -->
  <section [ngClass]="['section-background', service.backgroundClass]" *ngFor="let service of services; let i = index">
    <div class="section-gap">
      <div class="container">
        <div class="section-title-wrapper">
          <div class="row">
            <div class="col-sm-12 col-md-10 mx-auto text-center">
              <h2>
                <i [ngClass]="service.icon.name"></i>
                {{ service.title }}
              </h2>
              <p class="mb-0" *ngIf="service.subtitle">{{ service.subtitle }}</p>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="i%2 == 0">
          <div class="col-md-3 mb-2" *ngIf="service.image">
            <img class="img-solution" [alt]="service.title" src="assets/img/solutions/{{service.image}}" />
          </div>
          <div [ngClass]="{'col-md-8': (service.image != undefined), 'col-sm-12': !service.image}">
            <p [innerHtml]="service.showExtendedContent ? service.content : getShortContent(service.content)"
              (click)="service.showExtendedContent = !service.showExtendedContent"></p>
            <app-technologies-list [hidden]="!service.showExtendedContent" [keys]="service.technologies" [technologies]="technologies"
              *ngIf="service.technologies && service.technologies.length > 0"></app-technologies-list>
          </div>
        </div>
        <div class="row" *ngIf="i%2 != 0">
          <div [ngClass]="{'col-md-8': (service.image != undefined), 'col-sm-12': !service.image}">
            <p [innerHtml]="service.showExtendedContent ? service.content : getShortContent(service.content)"
              (click)="service.showExtendedContent = !service.showExtendedContent"></p>
            <app-technologies-list [hidden]="!service.showExtendedContent" [keys]="service.technologies" [technologies]="technologies"
              color="white" *ngIf="service.technologies && service.technologies.length > 0"></app-technologies-list>
          </div>
          <div class="col-md-3" *ngIf="service.image">
            <img class="img-solution" [alt]="service.title" src="assets/img/solutions/{{service.image}}" />
          </div>
        </div>
      </div>
      <div class="row-fluid d-flex justify-content-center" *ngIf="isContentLong(service.content)">
        <div class="btn-extend-section" *ngIf="!service.showExtendedContent" (click)="service.showExtendedContent = true">
          <i class="ion-ios-arrow-dropdown-circle-outline font-2x"></i>
        </div>
        <div class="btn-extend-section" *ngIf="service.showExtendedContent" (click)="service.showExtendedContent = false">
          <i class="ion-ios-arrow-dropup-circle-outline font-2x"></i>
        </div>
      </div>
    </div>
  </section>
</div>