<!-- Slick slider Client logo list -->
<div class="container">
	<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
		<div ngxSlickItem *ngFor="let slide of logoList" class="slide">
			<a href="{{ slide.link }}" target="_blank" *ngIf="slide?.link">
				<img src="{{ slide?.logo_path }}" class="img-fluid" alt="client logo" />
			</a>
			<a href="javascript:void(0);" *ngIf="!slide?.link">
				<img src="{{ slide?.logo_path }}" class="img-fluid" alt="client logo" />
			</a>
		</div>
	</ngx-slick-carousel>
</div><!-- container closed -->