import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'angly-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.css']
})
export class ExpertiseComponent implements OnInit {
  expertise: any;
  technologies: any;

  constructor(
    private pageTitleService: PageTitleService,
    private service: ChkService) { }

  ngOnInit() {
    /* Page title */
    this.pageTitleService.setTitle(' Expertise');

    /* Page subTitle */
    this.pageTitleService.setSubTitle(' ');

    this.service.getTechnologies().
      subscribe(response => {
        this.technologies = response;
      },
        err => console.log(err),
        () => this.technologies
      );

    this.service.getExpertise().
      subscribe(response => { this.expertise = response; },
        err => console.log(err),
        () => this.expertise
      );
  }

  isContentLong(content: string): boolean {
    return (content.length > 153);
  }

  getShortContent(content: string) {
    return this.isContentLong(content) ? (content.substr(0, 150) + '...') : content;
  }

}
