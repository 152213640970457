<div class="contact-widget section-gap">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-6 col-lg-6 mb-5 text-center">
				<div class="contact-icon mb-4">
				<i class="fa fa-envelope-o text-warning fa-3x"></i>
				</div>
				<h4 class="text-warning mb-3"> Send Us Mail </h4>
				<div *ngFor="let email of contact?.email">
					<h5 *ngIf="email?.status == 0">
						<a class="text-dark" href="mailto:{{email?.value}}">{{email?.value}}</a>
					</h5>
				</div>
			</div>
			<div class="col-sm-12 col-md-6 col-lg-6 mb-3 text-center">
				<div class="contact-icon mb-4">
				<i class="fa fa-location-arrow text-warning fa-3x"></i>
				</div>
				<h4 class="text-warning mb-3"> Our Location </h4>
				<div>
					<h5 *ngIf="contact?.location">
						<a class="text-dark">{{contact?.location}}</a>
					</h5>
				</div>
			</div>
	   </div>
	</div>
</div>
<div class="contact-info">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-7">
				<angly-map width="650" height="600"></angly-map>
				<!-- <div class="google-maps mb-3">
					<div class="mapouter">
					   <div class="gmap_canvas">
					      <iframe class="border-rad" width="650" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=Mohali%2C%20IN&t=&z=14&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
					      </iframe> -->
					      <!-- embed google map by <a href="http://www.embedgooglemap.net">embedgooglemap.net</a> -->
					   <!-- </div>
					   <style>.mapouter{overflow:hidden;height:600px;width:650px;}.gmap_canvas {background:none!important;height:600px;width:650px;}</style>
					</div>
				</div> -->
			</div>
			<div class="col-sm-12 col-md-12 col-lg-5">
				<div angly-sendMessage></div>
			 </div>
		 </div>
	</div>
</div>