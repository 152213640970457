import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'angly-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {
  services: any;
  technologies: any;

  constructor(private pageTitleService: PageTitleService, private service: ChkService) { }

  ngOnInit() {
    /* Page title */
    this.pageTitleService.setTitle(' Our Solutions');

    /* Page subTitle */
    /*this.pageTitleService.setSubTitle(' Our latest news and learning articles. ');*/

    this.service.getTechnologies().
      subscribe(response => {
        this.technologies = response;
      },
        err => console.log(err),
        () => this.technologies
      );

    this.service.getServices().
      subscribe(response => { this.services = response; },
        err => console.log(err),
        () => this.services
      );
  }

  isContentLong(content: string): boolean {
    return (content.length > 153);
  }

  getShortContent(content: string) {
    return this.isContentLong(content) ? (content.substr(0, 150) + '...') : content;
  }

}
