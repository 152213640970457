import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ExpertiseComponent } from './expertise/expertise.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  }, {
    path: 'solutions',
    component: SolutionsComponent
  }, {
    path: 'expertise',
    component: ExpertiseComponent
  }, {
    path: 'about',
    component: AboutComponent
  }
  /*
  {
    path: '',
    loadChildren: './session/session.module#SessionModule'
  }
  */
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
