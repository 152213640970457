import { Component, HostListener, OnInit } from '@angular/core';
import { PageTitleService } from './core/page-title/page-title.service';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { trigger, state, style, transition, animate } from '@angular/animations';

declare const jQuery;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [
        animate(300)
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  title = 'app';
  url: string;
  isHome = true;
  isBlog = false;
  isCommon = false;
  fixedHeaderClass = false;
  showInstallMessage = false;

  /* Variables */
  headerTitle$ = this.pageTitleService.title;
  headerSubTitle$ = this.pageTitleService.subTitle;
  featuredPost: any;

  constructor(private pageTitleService: PageTitleService, public router: Router, private swUpdate: SwUpdate) {
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('There is a new version available. Do you want to update now?')) {
          window.location.reload();
        }
      });
    }

    setTimeout(() => {
      // Detects if device is in standalone mode
      const navigator: any = window.navigator;
      const isInStandaloneMode = () => ('standalone' in navigator) && (navigator.standalone);
      // Checks if should display install popup notification:
      const isAlreadyClicked = window.localStorage.getItem('installMessageClicked');
      if ((!isAlreadyClicked || isAlreadyClicked === '0') && this.isIos() && !isInStandaloneMode()) {
        this.showInstallMessage = true;
      }
    }, 1000);
  }

  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    if (event.path && (event.path[0].scrollTop > 0)) {
      this.fixedHeaderClass = true;
    } else {
      this.fixedHeaderClass = false;
    }
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  addToggleClass() {
    jQuery('body').toggleClass('rtl-enable');
  }
}
