<div class="expertise-container">
  <!-- Video section -->
  <section [ngClass]="['section-gap', 'section-background', item.backgroundClass]" *ngFor="let item of expertise; let i = index">
    <div class="container">
      <div class="section-title-wrapper">
        <div class="row">
          <div class="col-sm-12 col-md-10 mx-auto text-center">
            <h2>
              <i [ngClass]="'ion-' + item.icon" *ngIf="item.icond"></i>
              {{ item.title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-lg-10">
          <div *ngIf="!item.showExtendedContent">
            <h4 *ngIf="item.content[0].title">{{ item.content[0].title }}</h4>
            <div class="d-flex justify-content-center">
              <img class="img-fluid" [alt]="item.content[0].title || item.title" src="assets/img/expertise/{{ item.content[0].image}}"
                *ngIf="item.content[0].image" />
            </div>
            <p [innerHtml]="getShortContent(item.content[0].text)" (click)="item.showExtendedContent = !item.showExtendedContent"></p>
          </div>

          <div *ngIf="item.showExtendedContent">
            <div *ngFor="let p of item.content">
              <h4 *ngIf="p.title">{{ p.title }}</h4>
              <div class="d-flex justify-content-center">
                <img class="img-expertise img-fluid" [alt]="p.title || item.title" src="assets/img/expertise/{{ p.image }}"
                  *ngIf="p.image" />
              </div>
              <p [innerHtml]="p.text"></p>
            </div>
          </div>
          <app-technologies-list [hidden]="!item.showExtendedContent" *ngIf="item.technologies && item.technologies.length > 0"
            [keys]="item.technologies" [technologies]="technologies"></app-technologies-list>
        </div>
      </div>
    </div>
    <div class="row-fluid d-flex justify-content-center">
      <div class="btn-extend-section" *ngIf="!item.showExtendedContent" (click)="item.showExtendedContent = true">
        <i class="ion-ios-arrow-dropdown-circle-outline font-2x"></i>
      </div>
      <div class="btn-extend-section" *ngIf="item.showExtendedContent" (click)="item.showExtendedContent = false">
        <i class="ion-ios-arrow-dropup-circle-outline font-2x"></i>
      </div>
    </div>
  </section>
</div>